export const STATUSES = {
  DELETED:
    /* Status */
    getText('Deleted'),
  PUBLISHED:
    /* Status */
    getText('Published'),
  DRAFT:
    /* Status */
    getText('Draft'),
  ARCHIVED:
    /* Status */
    getText('Expired'),
  FROZEN:
    /* Status */
    getText('Frozen'),
  HIDDEN:
    /* Status */
    getText('Hidden'),
  CHECKOUT:
    /* Status */
    getText('In Cart'),
  INVOICE:
    /* Status, awaiting payment */
    getText('Invoiced'),
  PULLED:
    /* Status */
    getText('Pulled from site'),
  APPROVED:
    /* Status */
    getText('Approved'),
  PENDING:
    /* Status */
    getText('Pending'),
  REJECTED:
    /* Status */
    getText('Rejected'),
  SPAM:
    /* Status */
    getText('Spam'),
};

export type ListingStatus = keyof typeof STATUSES;
